var _states;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

////////////////////////////////////////////////////////////////////////////////
// Welcome to @reach/tooltip!
//
// Quick definitions:
//
// - "on rest" or "rested on": describes when the element receives mouse hover
//   after a short delay (and hopefully soon, touch longpress).
//
// - "activation": describes a mouse click, keyboard enter, or keyboard space.
//
// Only one tooltip can be visible at a time, so we use a global state chart to
// describe the various states and transitions between states that are
// possible. With all the timeouts involved with tooltips it's important to
// "make impossible states impossible" with a state machine.
//
// It's also okay to use these module globals because you don't server render
// tooltips. None of the state is changed outside of user events.
//
// There are a few features that are important to understand.
//
// 1. Tooltips don't show up until the user has rested on one, we don't
//    want tooltips popupping up as you move your mouse around the page.
//
// 2. Once any tooltip becomes visible, other tooltips nearby should skip
//    resting and display immediately.
//
// 3. Tooltips stick around for a little bit after blur/mouseleave.
//
// TODO: Research longpress tooltips on Android, iOS
// - Probably want to position it by default above, since your thumb
//   is below and would cover it
// - I'm thinking after longpress, display the tooltip and cancel any click
//   events. Then on touchend, so they can read it display the tooltip for
//   a little while longer in case their hand was obstructing the tooltip.

/* eslint-disable default-case */
import React, { Fragment, cloneElement, Children, useState, useRef, forwardRef, useEffect } from "react";
import { useId } from "@reach/auto-id";
import { wrapEvent, checkStyles, useForkedRef, makeId } from "@reach/utils";
import Portal from "@reach/portal";
import VisuallyHidden from "@reach/visually-hidden";
import { useRect } from "@reach/rect";
import PropTypes from "prop-types"; ////////////////////////////////////////////////////////////////////////////////
// ~The states~
// nothing goin' on

var IDLE = "idle"; // we're considering showing the tooltip, but we're gonna wait a sec

var FOCUSED = "focused"; // IT'S ON

var VISIBLE = "visible"; // Focus has left, but we want to keep it visible for a sec

var LEAVING_VISIBLE = "leavingVisible"; // The user clicked the tool, so we want to hide the thing, we can't just use
// IDLE because we need to ignore mousemove, etc.

var DISMISSED = "dismissed";
var chart = {
  initial: IDLE,
  states: (_states = {}, _states[IDLE] = {
    enter: clearContextId,
    on: {
      mouseenter: FOCUSED,
      focus: VISIBLE
    }
  }, _states[FOCUSED] = {
    enter: startRestTimer,
    leave: clearRestTimer,
    on: {
      mousemove: FOCUSED,
      mouseleave: IDLE,
      mousedown: DISMISSED,
      blur: IDLE,
      rest: VISIBLE
    }
  }, _states[VISIBLE] = {
    on: {
      focus: FOCUSED,
      mouseenter: FOCUSED,
      mouseleave: LEAVING_VISIBLE,
      blur: LEAVING_VISIBLE,
      mousedown: DISMISSED,
      selectWithKeyboard: DISMISSED,
      globalMouseMove: LEAVING_VISIBLE
    }
  }, _states[LEAVING_VISIBLE] = {
    enter: startLeavingVisibleTimer,
    leave: function leave() {
      clearLeavingVisibleTimer();
      clearContextId();
    },
    on: {
      mouseenter: VISIBLE,
      focus: VISIBLE,
      timecomplete: IDLE
    }
  }, _states[DISMISSED] = {
    leave: function leave() {
      // allows us to come on back later w/o entering something else first
      context.id = null;
    },
    on: {
      mouseleave: IDLE,
      blur: IDLE
    }
  }, _states)
}; // chart context allows us to persist some data around, in Tooltip all we use
// is the id of the current tooltip being interacted with.

var context = {
  id: null
};
var state = chart.initial; ////////////////////////////////////////////////////////////////////////////////
// Finds the next state from the current state + action. If the chart doesn't
// describe that transition, it will throw.
//
// It also manages lifecycles of the machine, (enter/leave hooks on the state
// chart)

function transition(action, newContext) {
  var stateDef = chart.states[state];
  var nextState = stateDef.on[action]; // Really useful for debugging
  // console.log({ action, state, nextState, contextId: context.id });
  // !nextState && console.log('no transition taken')

  if (!nextState) {
    return;
  }

  if (stateDef.leave) {
    stateDef.leave();
  }

  if (newContext) {
    context = newContext;
  }

  var nextDef = chart.states[nextState];

  if (nextDef.enter) {
    nextDef.enter();
  }

  state = nextState;
  notify();
} ////////////////////////////////////////////////////////////////////////////////
// Subscriptions:
//
// We could require apps to render a <TooltipProvider> around the app and use
// React context to notify Tooltips of changes to our state machine, instead
// we manage subscriptions ourselves and simplify the Tooltip API.
//
// Maybe if default context could take a hook (instead of just a static value)
// that was rendered at the root for us, that'd be cool! But it doesn't.


var subscriptions = [];

function subscribe(fn) {
  subscriptions.push(fn);
  return function () {
    subscriptions.splice(subscriptions.indexOf(fn), 1);
  };
}

function notify() {
  subscriptions.forEach(function (fn) {
    return fn(state, context);
  });
} ////////////////////////////////////////////////////////////////////////////////
// Timeouts:
// Manages when the user "rests" on an element. Keeps the interface from being
// flashing tooltips all the time as the user moves the mouse around the screen.


var restTimeout;

function startRestTimer() {
  clearTimeout(restTimeout);
  restTimeout = setTimeout(function () {
    return transition("rest");
  }, 100);
}

function clearRestTimer() {
  clearTimeout(restTimeout);
} // Manages the delay to hide the tooltip after rest leaves.


var leavingVisibleTimer;

function startLeavingVisibleTimer() {
  clearTimeout(leavingVisibleTimer);
  leavingVisibleTimer = setTimeout(function () {
    return transition("timecomplete");
  }, 500);
}

function clearLeavingVisibleTimer() {
  clearTimeout(leavingVisibleTimer);
} // allows us to come on back later w/o entering something else first after the
// user leaves or dismisses


function clearContextId() {
  context.id = null;
} ////////////////////////////////////////////////////////////////////////////////
// useTooltip


export function useTooltip(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      idProp = _ref.id,
      onMouseEnter = _ref.onMouseEnter,
      onMouseMove = _ref.onMouseMove,
      onMouseLeave = _ref.onMouseLeave,
      onFocus = _ref.onFocus,
      onBlur = _ref.onBlur,
      onKeyDown = _ref.onKeyDown,
      onMouseDown = _ref.onMouseDown,
      forwardedRef = _ref.ref,
      DEBUG_STYLE = _ref.DEBUG_STYLE;

  var id = useId(idProp);

  var _useState = useState(DEBUG_STYLE ? true : id === null ? false : context.id === id && state === VISIBLE),
      isVisible = _useState[0],
      setIsVisible = _useState[1]; // hopefully they always pass a ref if they ever pass one


  var ownRef = useRef();
  var ref = useForkedRef(forwardedRef, ownRef);
  var triggerRect = useRect(ownRef, isVisible);
  useEffect(function () {
    return subscribe(function () {
      if (context.id === id && (state === VISIBLE || state === LEAVING_VISIBLE)) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, [id]);
  useEffect(function () {
    return checkStyles("tooltip");
  });
  useEffect(function () {
    var listener = function listener(event) {
      if ((event.key === "Escape" || event.key === "Esc") && state === VISIBLE) {
        transition("selectWithKeyboard");
      }
    };

    document.addEventListener("keydown", listener);
    return function () {
      return document.removeEventListener("keydown", listener);
    };
  }, []);

  var handleMouseEnter = function handleMouseEnter() {
    transition("mouseenter", {
      id: id
    });
  };

  var handleMouseMove = function handleMouseMove() {
    transition("mousemove", {
      id: id
    });
  };

  var handleFocus = function handleFocus(event) {
    if (window.__REACH_DISABLE_TOOLTIPS) return;
    transition("focus", {
      id: id
    });
  };

  var handleMouseLeave = function handleMouseLeave() {
    transition("mouseleave");
  };

  var handleBlur = function handleBlur() {
    // Allow quick click from one tool to another
    if (context.id !== id) return;
    transition("blur");
  };

  var handleMouseDown = function handleMouseDown() {
    // Allow quick click from one tool to another
    if (context.id !== id) return;
    transition("mousedown");
  };

  var handleKeyDown = function handleKeyDown(event) {
    if (event.key === "Enter" || event.key === " ") {
      transition("selectWithKeyboard");
    }
  };

  var trigger = {
    "aria-describedby": isVisible ? makeId("tooltip", id) : undefined,
    "data-reach-tooltip-trigger": "",
    ref: ref,
    onMouseEnter: wrapEvent(onMouseEnter, handleMouseEnter),
    onMouseMove: wrapEvent(onMouseMove, handleMouseMove),
    onFocus: wrapEvent(onFocus, handleFocus),
    onBlur: wrapEvent(onBlur, handleBlur),
    onMouseLeave: wrapEvent(onMouseLeave, handleMouseLeave),
    onKeyDown: wrapEvent(onKeyDown, handleKeyDown),
    onMouseDown: wrapEvent(onMouseDown, handleMouseDown)
  };
  var tooltip = {
    id: id,
    triggerRect: triggerRect,
    isVisible: isVisible
  };
  return [trigger, tooltip, isVisible];
} ////////////////////////////////////////////////////////////////////////////////
// Tooltip

export function Tooltip(_ref2) {
  var children = _ref2.children,
      label = _ref2.label,
      ariaLabel = _ref2.ariaLabel,
      id = _ref2.id,
      DEBUG_STYLE = _ref2.DEBUG_STYLE,
      rest = _objectWithoutPropertiesLoose(_ref2, ["children", "label", "ariaLabel", "id", "DEBUG_STYLE"]);

  var child = Children.only(children); // We need to pass some properties from the child into useTooltip
  // to make sure users can maintain control over the trigger's ref and events

  var _useTooltip = useTooltip({
    id: id,
    onMouseEnter: child.props.onMouseEnter,
    onMouseMove: child.props.onMouseMove,
    onMouseLeave: child.props.onMouseLeave,
    onFocus: child.props.onFocus,
    onBlur: child.props.onBlur,
    onKeyDown: child.props.onKeyDown,
    onMouseDown: child.props.onMouseDown,
    ref: child.ref,
    DEBUG_STYLE: DEBUG_STYLE
  }),
      trigger = _useTooltip[0],
      tooltip = _useTooltip[1];

  return React.createElement(Fragment, null, cloneElement(child, trigger), React.createElement(TooltipPopup, _extends({
    label: label,
    ariaLabel: ariaLabel
  }, tooltip, rest)));
}
Tooltip.displayName = "Tooltip";

if (process.env.NODE_ENV !== "production") {
  Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.node.isRequired,
    ariaLabel: PropTypes.string
  };
}

export default Tooltip; ////////////////////////////////////////////////////////////////////////////////
// TooltipPopup

export var TooltipPopup = forwardRef(function TooltipPopup(_ref3, forwardRef) {
  var label = _ref3.label,
      ariaLabel = _ref3.ariaLabel,
      position = _ref3.position,
      isVisible = _ref3.isVisible,
      id = _ref3.id,
      triggerRect = _ref3.triggerRect,
      rest = _objectWithoutPropertiesLoose(_ref3, ["label", "ariaLabel", "position", "isVisible", "id", "triggerRect"]);

  return isVisible ? React.createElement(Portal, null, React.createElement(TooltipContent, _extends({
    label: label,
    ariaLabel: ariaLabel,
    position: position,
    isVisible: isVisible,
    id: makeId("tooltip", id),
    triggerRect: triggerRect,
    ref: forwardRef
  }, rest))) : null;
});
TooltipPopup.displayName = "TooltipPopup";

if (process.env.NODE_ENV !== "production") {
  TooltipPopup.propTypes = {
    label: PropTypes.node.isRequired,
    ariaLabel: PropTypes.string,
    position: PropTypes.func
  };
} ////////////////////////////////////////////////////////////////////////////////
// TooltipContent
// Need a separate component so that useRect works inside the portal


var TooltipContent = forwardRef(function TooltipContent(_ref4, forwardedRef) {
  var label = _ref4.label,
      ariaLabel = _ref4.ariaLabel,
      _ref4$position = _ref4.position,
      position = _ref4$position === void 0 ? positionDefault : _ref4$position,
      isVisible = _ref4.isVisible,
      id = _ref4.id,
      triggerRect = _ref4.triggerRect,
      style = _ref4.style,
      rest = _objectWithoutPropertiesLoose(_ref4, ["label", "ariaLabel", "position", "isVisible", "id", "triggerRect", "style"]);

  var useAriaLabel = ariaLabel != null;
  var ownRef = useRef(null);
  var ref = useForkedRef(forwardedRef, ownRef);
  var tooltipRect = useRect(ownRef, isVisible);
  return React.createElement(Fragment, null, React.createElement("div", _extends({
    "data-reach-tooltip": true,
    role: useAriaLabel ? undefined : "tooltip",
    id: useAriaLabel ? undefined : id,
    children: label,
    style: _extends({}, style, {}, getStyles(position, triggerRect, tooltipRect)),
    ref: ref
  }, rest)), useAriaLabel && React.createElement(VisuallyHidden, {
    role: "tooltip",
    id: id
  }, ariaLabel));
});
TooltipContent.displayName = "TooltipContent";

if (process.env.NODE_ENV !== "production") {
  TooltipContent.propTypes = {};
} ////////////////////////////////////////////////////////////////////////////////
// feels awkward when it's perfectly aligned w/ the trigger


var OFFSET = 8;

function getStyles(position, triggerRect, tooltipRect) {
  var haventMeasuredTooltipYet = !tooltipRect;

  if (haventMeasuredTooltipYet) {
    return {
      visibility: "hidden"
    };
  }

  return position(triggerRect, tooltipRect);
}

function positionDefault(triggerRect, tooltipRect) {
  var collisions = {
    top: triggerRect.top - tooltipRect.height < 0,
    right: window.innerWidth < triggerRect.left + tooltipRect.width,
    bottom: window.innerHeight < triggerRect.bottom + tooltipRect.height + OFFSET,
    left: triggerRect.left - tooltipRect.width < 0
  };
  var directionRight = collisions.right && !collisions.left;
  var directionUp = collisions.bottom && !collisions.top;
  return {
    left: directionRight ? triggerRect.right - tooltipRect.width + window.pageXOffset + "px" : triggerRect.left + window.pageXOffset + "px",
    top: directionUp ? triggerRect.top - OFFSET - tooltipRect.height + window.pageYOffset + "px" : triggerRect.top + OFFSET + triggerRect.height + window.pageYOffset + "px"
  };
}