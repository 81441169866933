import React, { useRef, useState, useLayoutEffect } from "react";
import observeRect from "@reach/observe-rect";
import PropTypes from "prop-types"; ////////////////////////////////////////////////////////////////////////////////
// Rect

export function Rect(_ref) {
  var onChange = _ref.onChange,
      _ref$observe = _ref.observe,
      observe = _ref$observe === void 0 ? true : _ref$observe,
      children = _ref.children;
  var ref = React.useRef(null);
  var rect = useRect(ref, observe, onChange);
  return children({
    ref: ref,
    rect: rect
  });
}
Rect.displayName = "Rect";

if (process.env.NODE_ENV !== "production") {
  Rect.propTypes = {
    children: PropTypes.func,
    observe: PropTypes.bool,
    onChange: PropTypes.func
  };
} ////////////////////////////////////////////////////////////////////////////////
// useRect


export function useRect(nodeRef, observe, onChange) {
  if (observe === void 0) {
    observe = true;
  }

  var _useState = useState(null),
      rect = _useState[0],
      setRect = _useState[1];

  var observerRef = useRef(null);
  useLayoutEffect(function () {
    var cleanup = function cleanup() {
      observerRef.current && observerRef.current.unobserve();
    };

    if (!nodeRef.current) {
      console.warn("You need to place the ref");
      return cleanup;
    }

    if (!observerRef.current && nodeRef.current) {
      observerRef.current = observeRect(nodeRef.current, function (rect) {
        onChange && onChange(rect);
        setRect(rect);
      });
    }

    observe && observerRef.current.observe();
    return cleanup; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observe, onChange]);
  return rect;
}
export default Rect;