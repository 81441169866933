import React from "react";
import { useTooltip, TooltipPopup } from "@reach/tooltip";
import Portal from "@reach/portal";

import PropTypes from "prop-types";

import "./Tooltip.scss";

const propTypes = {};

const Tooltip = ({ children, label, "aria-label": ariaLabel, type = "info" }) => {
    // Get the props from useTooltip. It's important we pass any refs the children may have into this function. The tooltip
    // needs to attach it's own ref to the children and if we don't make it aware of the existing ref it will
    // overwrite it.
    const [trigger, tooltip] = useTooltip({ ref: children.ref });

    // Destructure off what we need to position the triangle
    const { isVisible, triggerRect } = tooltip;
    const tooltipTrianglePosition = triggerRect
        ? { left: triggerRect.left - 10 + triggerRect.width / 2, top: triggerRect.bottom + window.scrollY }
        : {};
    return (
        <>
            {React.cloneElement(children, trigger)}
            {isVisible && (
                // The Triangle. We position it relative to the trigger, not the popup
                // so that collisions don't have a triangle pointing off to nowhere.
                // Using a Portal may seem a little extreme, but we can keep the
                // positioning logic simpler here instead of needing to consider
                // the popup's position relative to the trigger and collisions
                <Portal>
                    <div
                        className="sc-tooltip-triangle"
                        data-type={type}
                        style={{
                            left: tooltipTrianglePosition.left,
                            top: tooltipTrianglePosition.top,
                        }}
                    />
                </Portal>
            )}
            <TooltipPopup
                {...tooltip}
                label={label}
                data-type={type}
                aria-label={ariaLabel}
                className="sc-tooltip"
                position={centered}
            />
        </>
    );
};

function centered(triggerRect, tooltipRect) {
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - 2;
    return {
        left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
        top: triggerRect.bottom + 4 + window.scrollY,
    };
}

Tooltip.propTypes = propTypes;

export default Tooltip;
